.MyAdsContainer{
    // margin-left: 16px;
    // margin-right: 16px;
    // padding-top: 24px;
    // margin-bottom: 18.33px;
    .MyAds-choicer{
        margin-left: auto;
        margin-right: 17px;
    }
    .center{
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
    }
    .AdCreatingMy{
        position: fixed;
        width: 100%;
        // height: 100%;
        background: rgb(24, 34, 45);
        display: flex;
        flex-direction: column;
        left: 0;
        top: 0;
        z-index: 2000;
        transition: 0.4s;
        transform: translateX(100%);
        min-height: calc(100vh);
        padding-bottom: 20px;
    }
    .new-textarea{
        border: 1.33px solid #636267;
        background-color: #18222d;
        border-radius: 10px 10px 6px 6px;
        padding: 12px 16px;
    }
    .details-enter{
        transform: translateX(0%);
        transition:  0.4s;
    }
    .details-enter-active{
        transform: translateX(0%);
        transition: 0.4s;        transition: 0.4s;
    }
    .details-enter-done{
        transform: translateX(0%);
        transition: 0.4s;
    }

    .details-exit{
        transform: translateX(100%);
        transition: 0.4s;
    }
    .details-exit-active{
        transform: translateX(100%);
        transition: 0.4s;
        transition: 0.4s;
    }
    .details-exit-done{
        transition: 0.4s;
    }
}
.stage-compact{
    margin-top: 23px;
    
}
.MyAds{
    margin-left: auto;
    margin-right: auto;
    color: rgb(255, 255, 255);
    font-family: "SF Pro Display 600";
    font-size: 17.67px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0%;
    text-align: center;
}
.counter__block{
    display: flex;
    gap: 8px;
    .number-of-transactions{
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 3px;
        padding: 10.33px 17.33px 9.33px 17.33px;
        border-radius: 11.67px;
        /* Cards */
        background: rgb(32, 48, 63);
        p:nth-child(1){
            color: rgb(255, 255, 255);
            font-family: "SF Pro Display 600";
            font-size: 20.72px;
            font-weight: 600;
            line-height: 25px;
            letter-spacing: 0%;
            text-align: left;           
        }
        p:nth-child(2){
            color: rgb(181, 206, 217);
            font-family: "SF Pro Display";
            font-size: 14.67px;
            font-weight: 500;
            line-height: 17.67px;
            letter-spacing: 0%;
            text-align: left;  
            max-width: 88px;          
        }
    }
}
.MyAds__FullPicker{
    margin-top: 7.67px;
}
.YourAds{
    align-items: center;
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    margin-left: 17.27px;
    margin-right: 17.27px;
    p{
        color: rgb(132, 137, 143);
        font-family: "SF Pro Display 400";
        font-size: 13.33px;
        font-weight: 400;
        line-height: 15.64px;
        letter-spacing: 2%;
        text-align: left;
        text-transform: uppercase;        
    }
    .sortBy{
        display: flex;
        gap: 4px;
    }
    .sortByPar{
        color: rgb(46, 165, 255);
        font-family: SF Pro Display;
        font-size: 13.33px;
        font-weight: 400;
        line-height: 15.64px;
        letter-spacing: 2%;
        text-align: right;
        text-transform: uppercase;
    }
    .upDown{
        width: 10.74px;
        height: 12.32px;
    }
}
.AdCreactingFunction{
    border-radius: 10px;
    background: rgb(32, 48, 63);
    padding-top: 9px;
    padding-bottom: 9px;
    padding-left: 18px;
    padding-right: 18px;
    width: 100%;
    display: flex;
    gap: 13.67px;
    margin-top: 8px;
    align-items: center;
    img{
        width: 24.67px;
        height: 24.67px;
    }
    p{
        color: rgb(46, 165, 255);
        font-family: "SF Pro Display";
        font-size: 17.33px;
        font-weight: 500;
        line-height: 18.31px;
        letter-spacing: 0%;
        text-align: left;
    }

}
.AdsContainer{
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    gap: 8px;
}
.block{
    transition: 0.5s;
}
.aboutMeTitle p{
    color: rgb(255, 255, 255);
    font-family: "SF Pro Display 600";
    font-size: 20.72px;
    font-weight: 600;
    line-height: 25px;
    letter-spacing: 0%;
    text-align: left;
}

.modal-reaction{
    border-radius: 50px;
    background-color: #20303f;
    width: 100%;
    padding: 0px 16px;
}
.modal-reaction .white-block{
    border-radius: 19px;
    width: 53px;
    height: 4px;
    margin-top: 14px;
    
}
.userLink{
    display: flex;
    flex-direction: column;
    gap: 2px;
}
.telegramLink{
    cursor: pointer;
    font-family:"SF Pro Display";
    font-weight: 500;
    font-size: 14px;
    line-height: 120%;
    text-decoration: underline;
    text-decoration-skip-ink: none;
    text-align: center;
    color: #2ea5ff;
    margin-top: 5px;
    text-underline-offset: 2px;
}
.blueLine{
    width: 100%;
    height: 1px;
    background-color:   #2ea5ff;;
}
.creationTime{
    font-family: "SF Pro Display";
    font-weight: 500;
    font-size: 11px;
    line-height: 107%;
    text-align: center;
    color: #95979e;
    margin-top: 8px;
    
}
.creationTimeBlock{
    display: flex;
    gap: 4px;
    justify-content: center;
    p{
        font-family: "SF Pro Display";
        font-weight: 500;
        font-size: 11px;
        line-height: 107%;
        text-align: center;
        color: #95979e;
        margin-top: 8px;
    }
}

.PickerContent{
    will-change: transform;
    position: static;
    min-width: calc(200vw);
    display: flex;
    transition: 0.4s;

    overflow-y: hidden;
    margin-left: -16px;

    z-index: 200;
    
}
.picker__block{
    align-self: flex-start;
    width: 50%;
    padding-left: 16px;
    padding-right: 16px;
}
.picker__block:nth-child(2){
}
.animation-block{
    display: flex;
    flex-direction: column;
    height: calc(calc(100vh) - 252px);
    justify-content: center;
    width: calc(100vw - 32px);
}
.animation-text{
    font-family: "SF Compact Rounded";
    font-weight: 500;
    font-size: 23px;
    text-align: center;
    color: #daf5fe;
    margin-top: 20px;
    max-width: 260px;
    margin-left: auto;
    margin-right: auto;
}

.shablonIcon{
    width: 30px;
    height: 30px;
    position: relative;
    border-radius: 6.67px;
    background-color: #5E5CE6;
}
.shablonIcon__inner{
    
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
}