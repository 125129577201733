.shablon-wrapper{
    min-width: 100vw;
    width: 100%;
    padding-top: 23px;
    padding-left: 16px;
    padding-right: 16px;
    left: 100vw;
    transform: translateX(0);
    top: 0;
    position: fixed;
    z-index: 200;
    background: rgb(24, 34, 45);
    padding-bottom: 20px;
    min-height: calc(100vh);
    height: calc(100vh);
    overflow-y: scroll;
    padding-bottom: 78px;
    .shablon-name{
        margin-top: 18px;
    }
    .shablon-title{
        color: rgb(255, 255, 255);
        font-family: "SF Pro Display 400";
        font-size: 17.67px;
        font-weight: 400;
        line-height: -1px;
        letter-spacing: 0%;
        text-align: left;
        margin-left: 16px;
    }
    .shablon-notice{
        max-width: 256px;
        color: rgb(218, 245, 254);
        font-family: "SF Pro Display 400";
        font-size: 13.33px;
        font-weight: 400;
        line-height: 15.64px;
        letter-spacing: 2%;
        text-align: left;
        margin-left: 16px;
        margin-top: 6px;
    }
    .shablon-description{
        margin-top: 18px;
    }
    .shablons-wrap{
        margin-top: 8px;
    }

}

