@keyframes appearTransform{
    0%{
        transform: translateY(25%);
    }
    100%{
        transform: translateY(0%);
    }
}
@keyframes appearScale{
    0%{
        transform: scale(0.85);
    }
    100%{
        transform: scale(1);
    }
}

@keyframes appearTransform{
    0%{
        transform: translateY(15%);
    }
    100%{
        transform: translateY(0%);
    }
}

@keyframes stepOne{
  0%{
    transform: translateX(0%);
  }
  50%{
    transform: translateX(calc(-100vw - 10px));
  }
  100%{
    transform: translateX(-100vw);
  }
}
@keyframes stepTwo{
  0%{
    transform: translateX(-100vw);
  }
  50%{
    transform: translateX(calc(-200vw - 10px));
  }
  100%{
    transform: translateX(-200vw);
  }
}

@keyframes oneBack{
  0%{
    transform: translateX(-100vw);
  }
  50%{
    transform: translateX(calc(10px));
  }
  100%{
    transform: translateX(0);
  }
}

@keyframes twoBack{
  0%{
    transform: translateX(-200vw);
  }
  50%{
    transform: translateX(calc(-100vw + 10px));
  }
  100%{
    transform: translateX(-100vw);
  }
}

.twoBack{
  animation: twoBack 0.55s  ;
  animation-fill-mode: both;
}
.oneBack{
  animation: oneBack 0.55s  ;
  animation-fill-mode: both;
}
.stepOne{
  animation: stepOne 0.55s  ;
  animation-fill-mode: both;
}

.stepTwo{
  animation: stepTwo 0.55s  ;
  animation-fill-mode: both;
}



.AdCreating{
    display: flex;
    flex-direction: column;
    position: relative;
    overflow-x: hidden;
    width: 100%;
    height: 100%;

    .ad-createOne-enter{
        animation: appearTransform 0.2s forwards;
        scale : 0.9;
    
      }
      .ad-createOne-enter-active{
        scale: 1;
        transition: 0.4s;
      }
      .ad-createOne-exit{
        scale: 1;
        transition: 0.4s;
      }
      .ad-createOne-exit-active{
        animation: appearTransform 0.2s forwards;
        scale : 0.9;
          
      }
    
    
      .ad-createTwo-enter{
        transform: translateX(100%);
        position: absolute;
    
      }
      .ad-createTwo-enter-active{
        transform: translateX(0%);
        transition: 0.4s;
      }
      .ad-createTwo-enter-done{
        transform: translateX(0%);
        transition: 0.4s;
      }
}






.ad-createOne-enter{
    animation: appearTransform 0.2s forwards;
    scale : 0.9;

  }
  .ad-createOne-enter-active{
    scale: 1;
    transition: 0.4s;
  }
  .ad-createOne-exit{
    scale: 1;
    transition: 0.4s;
  }
  .ad-createOne-exit-active{
    animation: appearTransform 0.2s forwards;
    scale : 0.9;
      
  }


  .ad-createTwo-enter{
    transform: translateX(100%);
    position: absolute;

  }
  .ad-createTwo-enter-active{
    transform: translateX(0%);
    transition: 0.4s;
  }
  .ad-createTwo-enter-done{
    transform: translateX(0%);
    transition: 0.4s;
  }

  .ad-createTwo-exit{
    opacity: 0;
  }
  
  .ad-createTwo-exit-done{
    opacity: 0;
  }



  .AdCreating__container{
    transition: 0.3s;
  }









  

.modal {
    transition: 0.4s;
}
.modal-enter {
    transform: translateY(100%);
}
.modal-enter-active{
    transform: translateY(0%);
    transition: 0.4s;
}
.modal-exit{
    transform: translateY(0%);
}
.modal-exit-active{
    transform: translateY(100%);
    transition: 0.4s;
}

.modal-two {
    transition: 0.4s;
}
.modal-two-enter {
    transform: translateY(100%);
}
.modal-two-enter-active{
    transform: translateY(0%);
    transition: 0.4s;
}
.modal-two-exit{
    transform: translateY(0%);
}
.modal-two-exit-active{
    transform: translateY(100%);
    transition: 0.4s;
}
.sub__wrapper{
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    gap: calc(8px);
    margin-top: calc(18px);
}
.SubBlock{
    display: flex;
    justify-content: space-between;
    padding: calc(15px);
    border-radius: calc(30px * 0.32);
    align-items: center;
    img{
        width: calc(20px*0.32);
        height: calc(33px * 0.32);
    }

/* Cards */
    background: rgb(32, 48, 63);
}
.subCategory__oneInput{
    margin-top: calc(55px * 0.32);
    flex-grow: 0;
}
.subCategory__container{
  padding-bottom: 60px ;
  z-index: 400;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    position: fixed;
    width: 100%;
    height: 100vh;
    
    left: 0;
    top: 0;
    background: rgb(24, 34, 45);
    padding-left: calc(48px* 0.32);
    padding-right: calc(48px* 0.32);
    padding-top: calc(56px* 0.32);
    .subCategory__oneInput{
      flex-grow: 0;
      margin-top: 0;
    }
}
.arrowRight{
    
    width: calc(20px*0.32);
    height: calc(33px * 0.32);
}



.adCreatingThree-wrapper{
  min-width: 100vw;
}
.AdCreating__container{
  padding-bottom: 76px;
  min-height: calc(100vh);
  display: flex;
  will-change: transform;
}
.datepicker{
  transform: translateY(100%);
  z-index: 200;  
}
.Modal-Portal{

  .datepicker-modal{
    position: fixed;
    top: 0;
    height: calc(100vh);
    z-index: -1;
    overflow-y: hidden;
    background-color: unset;  
  }
}
.dragBlock{
  z-index: -1;
  width: 100%;
  height: 200%;
  position: absolute;
  left: 100%;
  top: 0;
}