.responce-wrapper{
    // overflow-y: scroll;
    min-width: 100vw;
    width: 100vw;
    padding-bottom: 60px;
    padding-top: 18px;
    padding-left: 16px;
    padding-right: 16px;
    position: fixed;
    left: 100vw;
    height: calc(100vh);
    top: 0;
    overflow-y: auto;
    transform: translateX(100%);
    // height: calc(100vh);
    z-index: 100000;
    transition: 0.4s;
    background-color: #18222d;;
    will-change: transform;
}
.responce-wrapper.secondStep{
    transform: translateX(0%);
}
.responce-make-private{
    margin-top: 18px;
}
.responce-descriprion{
    margin-top: 18px;
}   