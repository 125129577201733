* {
    -webkit-tap-highlight-color: transparent;
}
.black{
    position: absolute;
    z-index: 5;
    width: 100%;
    height: fit-content;
    background: black;
    transition: 0.2s;
    opacity: 0;
    
}
.AllComponents{
    position: relative;
    background-color: #0d0c0c;
}
.opacity{
    opacity: 0.2;
}
.page{
    transition: 0.5s;
}

@keyframes appearTransform{
    0%{
        transform: translateY(25%);
    }
    100%{
        transform: translateY(0%);
    }
}
@keyframes appearScale{
    0%{
        transform: scale(0.85);
    }
    100%{
        transform: scale(1);
    }
}

@keyframes appearTransform{
    0%{
        transform: translateY(20%);
    }
    100%{
        transform: translateY(0%);
    }
}
.page{
    transition: 0.15s;
}
.page-enter {
    animation: appearTransform 0.15s forwards;
    scale : 0.9;
  }
  
  .page-enter-active {
    scale: 1;
    transition: 0.3s;
  }
  
  .page-exit {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 1;
    transform: scale(1);
  }
  
  .page-exit-active {
      opacity: 1;
    
      
    transition: 0.3s;
  }
