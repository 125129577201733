@font-face {
  font-family: 'trash';
  src:  url('fonts/trash.eot?y7i1t');
  src:  url('fonts/trash.eot?y7i1t#iefix') format('embedded-opentype'),
    url('fonts/trash.ttf?y7i1t') format('truetype'),
    url('fonts/trash.woff?y7i1t') format('woff'),
    url('fonts/trash.svg?y7i1t#trash') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="_icon-"], [class*=" _icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'trash' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

._icon-trash:before {
  content: "\e900";


}