
@font-face{
    font-family: 'SF Pro Display';
    src: url('../assets/San\ Francisco\ Pro\ Display/SF-Pro-Display-Medium.otf');
    font-weight: 500;

}
@font-face{
    font-family: 'SF Pro Display 400';
    src: url('../assets/San\ Francisco\ Pro\ Display/SF-Pro-Display-Regular.otf');
    font-weight: 400;

}
@font-face{
    font-family: 'SF Pro Display 600';
    src: url('../assets/San\ Francisco\ Pro\ Display/SF-Pro-Display-Semibold.otf');
    font-weight: 600;

}
@font-face{
    font-family: 'SF Pro Display 700';
    src: url('../assets/San\ Francisco\ Pro\ Display/SF-Pro-Display-Bold.otf');
    font-weight: 700;

}
@font-face{
    font-family: 'SF Pro Display 400';
    src: url('../assets/San\ Francisco\ Pro\ Display/SF-Pro-Display-Regular.otf');
    font-weight: 400;

}
@font-face{
    font-family: "Sf Compact Rounded";
    src: url('../assets/Sf\ Compact\ Rounded/sf-compact-rounded.ttf');
}
@font-face{
    font-family: "Sf Compact Rounded";
    src: url('../assets/Sf\ Compact\ Rounded/sf-compact-rounded.ttf');
}
@font-face{
    font-family: "SF Pro Text 600";
    src: url('../assets//San\ Francisco\ Pro\ Text/SFProText-Semibold.ttf ');
    font-weight: 600;
}
@font-face{
    font-family: "SF Pro Text 400";
    src: url('../assets//San\ Francisco\ Pro\ Text/SFProText-Regular.ttf ');
    font-weight: 400;
}
@font-face{
    font-family: "SF Pro Text";
    src: url('../assets//San\ Francisco\ Pro\ Text/SFProText-Medium.ttf ');
    font-weight: 500;
}
@font-face{
    font-family: "SF Pro Rounded";
    src: url('../assets/Sf-Pro-Rounded/SF-Pro-Rounded-Medium.otf');
    font-weight: 500;
}
@font-face{
    font-family: "SF Pro Rounded 400";
    src: url('../assets/Sf-Pro-Rounded/SF-Pro-Rounded-Regular.otf');
    font-weight: 400;
}
@font-face{
    font-family: "SF Pro Rounded 600";
    src: url('../assets/Sf-Pro-Rounded/SF-Pro-Rounded-Semibold.otf');
    font-weight: 600;
}